
import router from "./../router";
import {
  defineComponent,
  reactive,
  ref,
  UnwrapRef,
  onMounted,
  createVNode,
} from "vue";
import { notification, Modal } from "ant-design-vue";
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import validate from "../services/validator";
import { CloseOutlined, CloseCircleOutlined } from "@ant-design/icons-vue";
import questionService from "../services/questionProfile";
import commonService from "../services/common";

interface NewQuestionState {
  question: string;
  question_type: [];
  field_type: string;
  field_values: [];
  required_field: string;
}

export default defineComponent({
  props: ["QuestioProfileId"],
  emits: ["getAllQuestions"],
  watch: {
    // deep: true,
    // immediate: true,
    QuestioProfileId(val, oldVal) {
      if (val) {
        this.getQuestionDetails(val);
      }
      if (val == null) {
        this.setEmpty();
      }
    },
  },
  components: {
    CloseOutlined,
  },
  setup(props, { emit }) {
    const questionPageLoading = ref<boolean>(false);
    const allQuestions = ref([]);
    const questionLists = ref(null);
    const addQuestionLoading = ref<boolean>(false);
    const showNewQuestion = ref<boolean>(false);
    const optionFieldValidated = ref<boolean>(true);
    const optionRows = ref([]);
    const qProfileName = ref();
    const getQuestionDetails = async (id: any) => {
      questionPageLoading.value = true;
      showNewQuestion.value = false;
      try {
        const res = await questionService.getAllQuestions(id);
        if (res.data.data.success) {
          allQuestions.value = res.data.data.questions;
          qProfileName.value = res.data.data.profile_name;
          for (const resData of allQuestions.value) {
            if (resData.is_required === 1) {
              resData.active = true;
            } else {
              resData.active = false;
            }
          }
        }
        questionPageLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          questionPageLoading.value = false;
        }
      }
    };
    const deleteQuestionConfirm = async (questionId: any) => {
      Modal.confirm({
        title: "Are you sure?",
        icon: createVNode(CloseCircleOutlined),
        content: createVNode(
          "p",
          {},
          "Do you really want to delete this question? This process cannot be undone."
        ),
        okText: "Delete",
        okType: "danger",
        cancelText: "Cancel",
        class: "delte-confirm-modal",
        async onOk() {
          await deleteQuestion(questionId);
        },
      });
    };
    const deleteQuestion = async (id: any) => {
      try {
        const res = await questionService.deleteQuestion(id);
        if (res.data.data.success) {
          getQuestionDetails(props.QuestioProfileId);
          notify("Success", res.data.data.message, "success");
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          await notify("Error", error.response.data.data.message, "error");
        }
      }
    };
    const showEditConfirm = async (item: any) => {
      Modal.confirm({
        title: "Are you sure?",
        icon: createVNode(CloseCircleOutlined),
        content: createVNode("p", {}, "Do you want to edit this question?"),
        okText: "Update",
        okType: "danger",
        cancelText: "Cancel",
        class: "delte-confirm-modal",
        async onOk() {
          await editQuestion(item);
        },
        onCancel() {
          item.active = !item.active;
        },
      });
    };
    const editQuestion = async (data: any) => {
      try {
        const params = {
          is_required: data.active ? 1 : 0,
        };
        const res = await questionService.editQuestion(data.id, params);
        if (res.data.data.success) {
          notify("Success", res.data.data.message, "success");
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          await notify("Error", error.response.data.data.message, "error");
        }
      }
    };
    const addNewQuestion = async () => {
      showNewQuestion.value = true;
      setTimeout(() => {
        questionLists.value.scrollTop = questionLists.value.scrollHeight;
        answerFormRef.value.clearValidate();
        optionFieldValidated.value = true;
        answerModal.question = "";
        answerModal.question_type = [];
        answerModal.required_field = "false";
        optionRows.value = [];
      }, 200);
    };
    const answerFormRef = ref();
    const answerFormRules = validate.addQuestionValidation;
    const answerModal: UnwrapRef<NewQuestionState> = reactive({
      question: "",
      question_type: [],
      field_type: "",
      field_values: [],
      required_field: "",
    });
    const filterOption = (input: string, option: any) => {
      return (
        option.children[0].children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    };
    const notify = async (
      message: string,
      description: string,
      type: string
    ) => {
      (notification as any)[type]({
        message,
        description,
      });
    };
    const onQuestionTypeSelected = async () => {
      const qType: any = answerModal.question_type;
      if (qType === "Dropdown" || qType === "Radio" || qType === "Checkbox") {
        if (!optionRows.value.length) {
          optionRows.value.push({
            optionValue: "",
          });
        }

        setTimeout(() => {
          questionLists.value.scrollTop = questionLists.value.scrollHeight;
        }, 200);
      } else {
        optionRows.value = [];
      }
    };
    const addNewOptions = async () => {
      optionRows.value.push({
        optionValue: "",
      });
    };
    const removeOption = async (index: any) => {
      optionRows.value.splice(index, 1);
    };
    const saveNewQuestion = async (data: any) => {
      answerFormRef.value
        .validate()
        .then(async () => {
          if (
            data.question_type === "Short Text" ||
            data.question_type === "Long Text" ||
            data.question_type === "Number" ||
            data.question_type === "Datepicker" ||
            data.question_type === "Rating" ||
            data.question_type === "File"
          ) {
            data.field_type = "single";
            optionFieldValidated.value = true;
            data.field_values = [];
          }
          if (
            data.question_type === "Dropdown" ||
            data.question_type === "Radio" ||
            data.question_type === "Checkbox"
          ) {
            data.field_type = "multiple";
            optionFieldValidated.value = false;
            if (optionRows.value.length && optionRows.value.length >= 2) {
              for (const [i, option] of optionRows.value.entries()) {
                if (option.optionValue) {
                  optionFieldValidated.value = true;
                } else {
                  optionFieldValidated.value = false;
                }
              }
            } else {
              optionFieldValidated.value = false;
            }
            if (optionFieldValidated.value === true) {
              data.field_values = [];
              for (const option of optionRows.value) {
                data.field_values.push(option.optionValue);
              }
            }
          }
          if (data.required_field === true) {
            data.is_required = 1;
          } else {
            data.is_required = 0;
          }
          if (optionFieldValidated.value === true) {
            addQuestionLoading.value = true;
            try {
              const res = await questionService.addNewQuestion(
                props.QuestioProfileId,
                data
              );
              if (res.data.data.success) {
                await notify("Success", res.data.data.message, "success");
                answerFormRef.value.clearValidate();
                answerFormRef.value.resetFields();
                showNewQuestion.value = false;
                getQuestionDetails(props.QuestioProfileId);
              }
              addQuestionLoading.value = false;
            } catch (error: any) {
              if (error.response) {
                console.log("Error", error.response);
                await notify("Error", error.response.data.message, "error");
              }
              addQuestionLoading.value = false;
            }
          }
        })
        .catch((error: ValidateErrorEntity<NewQuestionState>) => {
          console.log("error", error);
        });
    };
    const closeNewQuestion = async () => {
      answerFormRef.value.clearValidate();
      answerFormRef.value.resetFields();
      showNewQuestion.value = false;
    };
    const setEmpty = async () => {
      qProfileName.value = undefined;
      showNewQuestion.value = false;
      allQuestions.value = [];
    };
    onMounted(() => {
      emit("getAllQuestions");
    });
    return {
      questionPageLoading,
      getQuestionDetails,
      addNewQuestion,
      deleteQuestionConfirm,
      deleteQuestion,
      allQuestions,
      questionLists,
      addNewOptions,
      optionRows,
      removeOption,
      showEditConfirm,
      optionFieldValidated,
      onQuestionTypeSelected,
      answerModal,
      answerFormRef,
      answerFormRules,
      filterOption,
      addQuestionLoading,
      saveNewQuestion,
      closeNewQuestion,
      showNewQuestion,
      commonService,
      qProfileName,
      setEmpty,
    };
  },
});
