export default {
  loginValidation: {
    email: [
      { required: true, message: 'Please enter your email', trigger: 'blur' },
      {
        message: 'Please enter a valid email',
        trigger: 'change',
        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      }
    ],
    password: [
      { required: true, message: 'Please enter your password', trigger: 'blur' }
      // { min: 6, max: 10, message: 'Length should be 6 to 8', trigger: 'change' }
    ]
  },
  signValidation: {
    agency_name: [
      { required: true, message: 'Please enter agency name', trigger: 'blur' },
    ],
    first_name: [
      { required: true, message: 'First name is required', trigger: 'blur' }
    ],
    last_name: [
      { required: true, message: 'Last name is required', trigger: 'blur' }
    ],
    email: [
      { required: true, message: 'Please enter email', trigger: 'blur' },
      {
        message: 'Please enter a valid email',
        trigger: 'change',
        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      }
    ],
    phone: [
      { required: true, message: 'Please enter phone number', trigger: 'blur' },
      {
        message: 'Please enter a valid phone number',
        triger: 'change',
        pattern: /^(\d{10}|\d{12})$/
      }
    ],
    city: [
      { required: true, message: 'City is required', trigger: 'blur' }
    ],
    postal_code: [
      { required: true, message: 'Postcode is required', trigger: 'blur' }
    ],
    country: [
      { required: true, message: 'Country is required', trigger: 'blur' }
    ],
    address1: [
      { required: true, message: 'Address1 is required', trigger: 'blur' }
    ]
  },
  referenceRequestValidation: {
    first_name: [
      { required: true, message: 'First name is required', trigger: 'blur' }
    ],
    last_name: [
      { required: true, message: 'Last name is required', trigger: 'blur' }
    ],
    email: [
      { required: true, message: 'Please enter email', trigger: 'blur' },
      {
        message: 'Please enter a valid email',
        trigger: 'change',
        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      }
    ],
    profile_id: [
      { required: true, message: 'Question profile is required', trigger: 'blur', type: 'array' }
    ],
    reference_required: [
      { required: true, message: 'Enter number of reference required', trigger: 'blur' }
    ],
  },
  addRefereeValidation: {
    referee_relationship: [
      { required: true, message: 'This field is required', trigger: 'blur' }
    ],
    first_name: [
      { required: true, message: 'First name is required', trigger: 'blur' }
    ],
    last_name: [
      { required: true, message: 'Last name is required', trigger: 'blur' }
    ],
    company:[
      { required: true, message: 'Company name is required', trigger: 'blur' }
    ],
    email: [
      { required: true, message: 'Please enter email', trigger: 'blur' },
      {
        message: 'Please enter a valid email',
        trigger: 'change',
        pattern: /^(([^<>()[\]\\_.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      }
    ],
    profile_id: [
      { required: true, message: 'Question profile is required', trigger: 'blur', type: 'array' }
    ],
    candidate_job:[
      { required: true, message: 'Candidate job is required', trigger: 'blur' }
    ],
    workstartmonth: [
      { required: true, message: 'This field is required', trigger: 'blur', type: 'date' }
    ],
    workstartyear: [
      { required: true, message: 'This field is required', trigger: 'blur', type: 'date' }
    ],
    workendmonth: [
      { required: true, message: 'This field is required', trigger: 'blur', type: 'date' }
    ],
    workendyear: [
      { required: true, message: 'This field is required', trigger: 'blur', type: 'date' }
    ],
  },
  webRefereeValidation: {
    first_name: [
      { required: true, message: 'First name is required', trigger: 'blur' }
    ],
    last_name: [
      { required: true, message: 'Last name is required', trigger: 'blur' }
    ],
    company: [
      { required: true, message: 'This field is required', trigger: 'blur' }
    ],
    candidate_job: [
      { required: true, message: 'This field is required', trigger: 'blur' }
    ],
    email: [
      { required: true, message: 'Please enter email', trigger: 'blur' },
      {
        message: 'Please enter a valid email',
        trigger: 'change',
        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      }
    ],
    workstartmonth: [
      { required: true, message: 'This field is required', trigger: 'blur', type: 'date' }
    ],
    workstartyear: [
      { required: true, message: 'This field is required', trigger: 'blur', type: 'date' }
    ],
    workendmonth: [
      { required: true, message: 'This field is required', trigger: 'blur', type: 'date' }
    ],
    workendyear: [
      { required: true, message: 'This field is required', trigger: 'blur', type: 'date' }
    ],
  },
  webRefereeAdditionalValidation: {
    referee_relationship:[
      { required: true, message: 'This field is required', trigger: 'blur' }
    ]
  },
  addQuestionValidation: {
    question:[
      { required: true, message: 'This field is required', trigger: 'blur' }
    ],
    question_type:[
      { required: true, message: 'This field is required', trigger: 'blur' }
    ]
  },
  addUserValidation: {
    first_name: [
      { required: true, message: 'First name is required', trigger: 'blur' }
    ],
    last_name: [
      { required: true, message: 'Last name is required', trigger: 'blur' }
    ],
    email: [
      { required: true, message: 'Please enter email', trigger: 'blur' },
      {
        message: 'Please enter a valid email',
        trigger: 'change',
        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      }
    ],
    role_id:[
      { required: true, message: 'Select permission', trigger: 'blur' }
    ]
  },
  priceValidation: {
    credits_till: [
      { required: true, message: 'Credit Count is required', trigger: 'blur' }
    ],
    price_per_unit: [
      { required: true, message: 'Price is required', trigger: 'blur' }
    ],
  },
  organisationValidation: {
    name: [
      { required: true, message: 'Name is required', trigger: 'blur' }
    ],
    redirect: [
      { required: true, message: 'Redirect url is required', trigger: 'blur' },
      // {
      //   message: 'Please enter a valid url',
      //   trigger: 'change',
      //   // pattern: /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/
      //   pattern: /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]?(:\d{4})+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/
      // }
    ],
  },
  addcreditValidation: {
    credits_till: [
      { required: true, message: 'Credit Count is required', trigger: 'blur' }
    ],
  },
}